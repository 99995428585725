"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isWithoutNulls(d) {
    return Object.values(d).every(function (value) { return !isNull(value); });
}
exports.isWithoutNulls = isWithoutNulls;
function isDefined(d) { return d !== undefined; }
exports.isDefined = isDefined;
function isNull(d) { return d === null; }
exports.isNull = isNull;
function isString(d) { return typeof d === 'string'; }
exports.isString = isString;
function isNumber(d) { return typeof d === 'number'; }
exports.isNumber = isNumber;
function isBoolean(d) { return typeof d === 'boolean'; }
exports.isBoolean = isBoolean;
